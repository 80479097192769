<template>
  <div>
    <el-button size="mini" type="primary" @click="showDialog">选择分销商</el-button>
    <BaseDialog
      class="dialog-warpper"
      v-if="visible"
      :dialogVisible.sync="visible"
      title="选择分销商"
      width="40%"
      @closeHandle="handleClose"
    >
      <template>
        <div class="select-container">
          <div class="title">分销商</div>
          <div class="select">
            <el-checkbox :indeterminate="isIndeterminate" class="all" v-model="checkAll">全部</el-checkbox>
            <el-checkbox-group v-model="selectedMerchantList">
              <el-checkbox v-for="item in allMerchantList" :key="item.id" :label="item.id">{{
                item.accountName
              }}</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="select-container">
          <div class="title">已选择分销商 <color-text-btn @click="cancelAll">取消全部</color-text-btn></div>
          <div class="select">
            <el-checkbox-group v-model="selectedMerchantList">
              <el-checkbox v-for="item in filterMerchantList" :key="item.id" :label="item.id">{{
                item.accountName
              }}</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="tip">选择了分销商后，该分销商定制的产品都会同步到公共产品管理上</div>
      </template>
      <template #footer>
        <loadingBtn type="primary" @click="onConfirm"> 确定 </loadingBtn>
        <el-button @click="onCancel"> 取消 </el-button>
      </template>
    </BaseDialog>
  </div>
</template>

<script>
import { deepClone, getDiffData } from '@/components/avue/utils/util'
import { selectMerchant } from '@/api/finishedProductManageApi'

export default {
  props: {
    allMerchantList: {
      type: Array,
      default: () => []
    },
    sup_this: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      visible: false,
      selectedMerchantList: [],
      selectedMerchantListCopy: []
    }
  },
  computed: {
    filterMerchantList() {
      return this.allMerchantList.filter(({ id }) => this.selectedMerchantList.includes(id))
    },
    checkAll: {
      get({ selectedMerchantList, allMerchantList }) {
        return selectedMerchantList.length == allMerchantList.length
      },
      set(bool) {
        if (bool) {
          this.selectedMerchantList.push(...this.allMerchantList.map(({ id }) => id))
          this.selectedMerchantList = [...new Set(this.selectedMerchantList)]
        }
        if (!bool) {
          this.selectedMerchantList = this.selectedMerchantList.filter((checkedId) => {
            return !this.allMerchantList.find(({ id }) => id == checkedId)
          })
        }
      }
    },
    isIndeterminate({ allMerchantList, selectedMerchantList }) {
      const curSelectData = selectedMerchantList.filter((checkeId) => {
        return allMerchantList.find(({ id }) => checkeId == id)
      })
      if (curSelectData.length == 0) return false
      if (curSelectData.length < allMerchantList.length) return true
      return false
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.selectedMerchantList = this.allMerchantList.filter((item) => item.isProductPublic == 1).map(({ id }) => id)
        this.selectedMerchantListCopy = deepClone(this.selectedMerchantList)
      }
    }
  },
  methods: {
    showDialog() {
      this.visible = true
    },
    handleClose() {
      this.visible = false
    },
    onCancel() {
      this.selectedMerchantList = this.selectedMerchantListCopy
      this.visible = false
      this.$message.warning('您取消了操作')
    },
    async onConfirm() {
      let createIdList = this.selectedMerchantList.filter((id) => !this.selectedMerchantListCopy.includes(id))
      let deleteIdList = this.selectedMerchantListCopy.filter((id) => !this.selectedMerchantList.includes(id))
      if (createIdList.length == 0) createIdList = undefined
      if (deleteIdList.length == 0) deleteIdList = undefined
      if (
        !getDiffData(this.selectedMerchantList, this.selectedMerchantListCopy) &&
        this.selectedMerchantListCopy.length == this.selectedMerchantList.length
      ) {
        this.visible = false
        this.$message.success('分配成功')
        return
      }
      try {
        const data = {
          createIdList,
          deleteIdList
        }
        const { code } = await selectMerchant(data)
        if (code != 0) return
        this.sup_this.getMerchantList()
        this.visible = false
        this.$message.success('分配成功')
      } catch (error) {
        this.$message.error('分配失败')
      }
    },
    cancelAll() {
      this.selectedMerchantList = []
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-warpper {
  .select-container {
    height: 248px;
    margin-bottom: 25px;
    .select {
      height: 200px;
      max-height: 200px;
      padding-left: 10px;
      overflow-y: auto;
      border: 1px solid #bbbbbb;
      border-top: none;
    }
    .title {
      height: 48px;
      line-height: 48px;
      padding-left: 10px;
      border: 1px solid #bbbbbb;
      .color-btn-component {
        font-size: 14px;
        margin-left: 10px;
      }
    }
  }
  ::v-deep {
    .el-checkbox {
      margin: 10px 10px 0 0;
    }
  }
  .tip {
    color: #bdb8bb;
  }
}
</style>
